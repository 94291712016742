import React, { useState } from 'react'
import { Button, Form, Modal, Input, Stack, SelectPicker } from 'rsuite'
import { UploadArea } from './UploadArea.tsx'
import { Attachment } from '@petshop/common'
import { createPresignedUrl, uploadAttachment } from '../api'
import { getFileName } from '../utils/utils.ts'
import { roomsAtom } from '../atoms'
import { useAtom } from 'jotai'
import { ChatModalMessage } from '../types'
import { S3_URL } from '../constants/env.ts'

type Props = {
  predefinedRoomId?: string
  show: boolean
  setShow: (show: boolean) => void
  onSubmit: (message: ChatModalMessage) => Promise<void>
}

const Textarea = React.forwardRef((props, ref) => <Input {...props} rows={4} as="textarea" ref={ref as never} />)

export const ChatModal: React.FC<Props> = ({ predefinedRoomId, show, setShow, onSubmit }) => {
  const [message, setMessage] = useState<string>('')
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [rooms] = useAtom(roomsAtom)
  const [roomId, setRoomId] = useState(predefinedRoomId)

  const uploadFiles = async (files: File[]) => {
    const atts = await Promise.all(
      files.map(async (file): Promise<Attachment> => {
        const { uploadUrl, fileId } = await createPresignedUrl(file.name, file.type, predefinedRoomId)
        await uploadAttachment(uploadUrl, file)
        return {
          type: file.type,
          url: `${S3_URL}/${fileId}`
        }
      })
    )
    setAttachments([...attachments, ...atts])
  }

  return (
    <Modal open={show} onClose={() => setShow(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>What would you like to say?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form fluid>
          {!predefinedRoomId && (
            <Form.Group className="mb-3" controlId="uploadForm.roomId">
              <Form.ControlLabel>Room to send in</Form.ControlLabel>
              <Form.Control
                name="roomId"
                data={rooms.map((r) => ({
                  label: r.name,
                  value: r.id
                }))}
                value={roomId}
                onChange={(value) => setRoomId(value)}
                accepter={SelectPicker}
                block
              />
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="uploadForm.message">
            <Form.ControlLabel>Your message</Form.ControlLabel>
            <Form.Control name="message" value={message} accepter={Textarea} onChange={setMessage} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="uploadForm.attachments">
            <Form.ControlLabel>Add image or video</Form.ControlLabel>
            {attachments.length > 0 && (
              <Stack alignItems="flex-start" direction="column">
                {attachments.map((attachment) => (
                  <Stack.Item>{getFileName(attachment.url)}</Stack.Item>
                ))}
              </Stack>
            )}
            <UploadArea
              onError={(error) => console.error(error)}
              onFileSelected={(files) => {
                void uploadFiles(files)
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="ghost" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button
          appearance="primary"
          onClick={() =>
            onSubmit({
              message: message,
              attachments: attachments,
              roomId: roomId!
            })
          }
          disabled={!message || !roomId}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
