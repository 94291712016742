import { ReactNode, FC } from 'react'
import { Container, Content, FlexboxGrid } from 'rsuite'
import styled from 'styled-components'

type Props = {
  children: ReactNode
}
export const FullScreenMessage: FC<Props> = ({ children }) => {
  return (
    <MessageContainer>
      <Message>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>{children}</FlexboxGrid.Item>
        </FlexboxGrid>
      </Message>
    </MessageContainer>
  )
}

const MessageContainer = styled(Container)`
  min-height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Message = styled(Content)``
