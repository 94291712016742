import { useToaster, Message } from 'rsuite'
import { ReactNode } from 'react'

type NotificationType = 'info' | 'success' | 'warning' | 'error'

export const useNotifications = () => {
  const toaster = useToaster()

  const messageElement = (message: ReactNode, type: NotificationType) => <Message type={type}>{message}</Message>

  const pushNotification = (message: ReactNode, type: NotificationType) => {
    toaster.push(messageElement(message, type), { placement: 'bottomCenter', duration: 5000 })
  }

  return {
    pushNotification
  }
}
