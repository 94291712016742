import { z } from 'zod'

export const NotificationSchema = z.object({
  id: z.string().optional(),
  title: z.string(),
  message: z.string(),
  read: z.boolean().optional(),
  user: z.string(),
  created: z.string().optional(),
  updated: z.string().optional()
})

export type Notification = z.infer<typeof NotificationSchema>