import styled from 'styled-components'
import { Input, Stack, Modal } from 'rsuite'
import { FC, useState } from 'react'
import { Attachment } from '@petshop/common'
import { BsFillImageFill, BsCameraVideoFill } from 'react-icons/bs'
import { PlainLink } from '../PlainLink.tsx'
import { AttachmentCarousel } from './AttachmentCarousel.tsx'

export const Message = styled.div`
  display: flex;
`
export const MessageContainer = styled.div<{ flow: 'left' | 'right' | 'center'; direction: 'column' | 'row' }>`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => (props.flow === 'left' ? 'flex-start' : props.flow === 'right' ? 'flex-end' : 'center')};
  ${Message} {
    padding: 8px 16px;
    align-self: ${(props) => (props.flow === 'left' ? 'flex-start' : 'flex-end')};
    border: 2px solid ${(props) => (props.flow === 'left' ? 'rgb(240,229,181)' : '#E54A49')};
    background-color: ${(props) => (props.flow === 'left' ? 'rgb(240,229,181)' : 'white')};
    color: ${(props) => (props.flow === 'left' ? 'black' : '#E54A49')};
    border-radius: 16px;
    margin-top: 8px;
    ${(props) => (props.flow === 'center' ? 'margin: 0 5px;' : '')}
    ${(props) => (props.flow === 'center' ? 'cursor: pointer;' : '')}
  }
  ${Message}:last-of-type {
    ${(props) => (props.flow === 'left' ? 'border-bottom-left-radius: 0' : props.flow === 'right' ? 'border-bottom-right-radius: 0' : '')};
  }
`
export const InputWithError = styled(Input)`
  &.error {
    outline: 3px solid rgb(from var(--rs-text-error) r g b / 25%);
  }
`
export const ErrorMessage = styled.div`
  color: #e54a49;
`

export const Attachments: FC<{ attachments: Attachment[] }> = ({ attachments }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const images = attachments.filter((a) => a.type.startsWith('image')).length
  const videos = attachments.filter((a) => a.type.startsWith('video')).length

  if (!images && !videos) {
    return null
  }

  return (
    <AttachmentStack spacing={8}>
      <PlainLink onClick={() => setShowModal(true)}>
        {images > 0 && (
          <>
            <BsFillImageFill /> {images} image(s)
          </>
        )}
        {videos > 0 && (
          <>
            <BsCameraVideoFill /> {videos} video(s)
          </>
        )}
      </PlainLink>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <AttachmentCarousel attachments={attachments} />
      </Modal>
    </AttachmentStack>
  )
}

const AttachmentStack = styled(Stack)`
  padding: 8px 0;
  display: flex;
  align-items: center;
`
