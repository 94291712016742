import { atom } from 'jotai'
import { atomWithRefresh } from './utils'
import { Thread, Room, Message, User, PrivateDiscussion, PrivateMessage, FriendRequest, Friends, PublicUser } from '@petshop/common'
import {
  login,
  getRooms,
  getUser,
  getPrivateDiscussion,
  getThread,
  getNotifications, getClosestLocation,
  fetchUsersByIds, getRoom
} from '../api'
import { SessionService } from '../utils/session.utils.ts'
import { clearQueryParams } from '../utils/utils.ts'
import dayjs from 'dayjs'

export const roomIdAtom = atom<string | undefined>(undefined)
export const roomsAtom = atomWithRefresh<Promise<Room[]>>(async () => {
  return getRooms()
})

export const roomAtom = atomWithRefresh<Promise<Room | undefined>>(async (get) => {
  const roomId = get(roomIdAtom)
  if (!roomId) {
    return undefined
  }
  return getRoom(roomId)
})

export const threadsAfterAtom = atom<Date>(dayjs().subtract(14, 'day').toDate())

export const threadsAtom = atom<Thread[]>([])

export const threadIdAtom = atom<string | undefined>(undefined)

export const threadAtom = atomWithRefresh<Promise<Thread | undefined>>(async (get) => {
  const threadId = get(threadIdAtom)
  const roomId = get(roomIdAtom)
  if (!threadId || !roomId) {
    return undefined
  }
  return getThread(threadId, roomId)
})

export const messagesAfterAtom = atom<Date>(dayjs().subtract(14, 'day').toDate())
export const messagesAtom = atom<Message[]>([])

export const userAtom = atomWithRefresh<Promise<User | undefined>>(async () => {
  const existingUser = SessionService.loadSession()

  const { token, username } = Object.fromEntries(new URLSearchParams(window.location.search).entries())

  clearQueryParams()
  SessionService.clearSession()

  let user: User | undefined = undefined
  if (token && username) {
    try {
      user = await login(username, token)
    } catch (e) {
      throw new Error('Failed to login.')
    }
  } else if (existingUser) {
    user = existingUser
  }

  if (user) {
    SessionService.saveSession(user)
  }

  return user
})

export const profileIdAtom = atom<string | undefined>(undefined)
export const profileAtom = atomWithRefresh<Promise<User | undefined>>(async (get) => {
  const profileId = get(profileIdAtom)
  if (!profileId) {
    return undefined
  }
  return getUser(profileId)
})

export const privateDiscussionAtom = atomWithRefresh<Promise<PrivateDiscussion | undefined>>(async (get) => {
  const profileId = get(profileIdAtom)
  const loggedInId = SessionService.loadSession()?.id
  if (!profileId || loggedInId === profileId) {
    return undefined
  }
  return getPrivateDiscussion(loggedInId!, profileId)
})

export const privateMessagesAfterAtom = atom<Date>(dayjs().subtract(14, 'day').toDate())

export const privateMessagesAtom = atom<PrivateMessage[]>([])

// Friendship

export const friendRequestSentAtom = atom<boolean>(false)
export const friendRequestsAtom = atom<FriendRequest[]>([])
export const friendsAtom = atom<Friends | null>(null)

export const notificationsAtom = atomWithRefresh(async () => {
  return getNotifications()
})

export const coordinatesAtom = atom<{ latitude: number; longitude: number } | undefined>(undefined)

export const locationAtom = atomWithRefresh(async (get) => {
  const coordinates = get(coordinatesAtom)
  if (!coordinates) {
    return undefined
  }
  return getClosestLocation(coordinates.latitude, coordinates.longitude)
})
const usersStorageAtom = atom<PublicUser[]>([])
export const usersAtom = atom((get) => get(usersStorageAtom), 
async (get, set, newValue: string[]) => {
  const current = get(usersStorageAtom)
  const toFetch = newValue.filter((u) => !current.find((cu) => cu.id === u))
  if (toFetch.length === 0) {
    set(usersStorageAtom, current)
    return
  }
  const newUsers = await fetchUsersByIds(toFetch)
  set(usersStorageAtom, [...current, ...newUsers])
})