import styled from 'styled-components'
import { PlainLink } from './PlainLink.tsx'
import { Stack } from 'rsuite'

export const ChatCardElement = styled.div<{ $colors?: { background: string; text: string; highlight: string } }>`
  display: flex;
  flex-direction: row;
  padding: 8px;
  margin: 2px 2px 8px;
  border-bottom: 2px solid #e4e4e4;
  background-color: ${(props) => props.$colors?.background || 'white'};
  ${(props) => props.$colors?.text && `color: ${props.$colors.text}`};
`
export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
export const ChatFooter = styled.small`
  margin: 4px 0 0 0;
`
export const ChatTitle = styled(Stack)`
  display: flex;
  flex-direction: row;
  margin: 8px 8px 0 8px;
`
export const ChatBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 4px 8px;
`
export const ChatRating = styled.span`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
`
export const UserBadge = styled(PlainLink)`
  padding: 4px 8px;
  border-radius: 12px;
  color: #e13737;
  background-color: #ffec84;
`
export const RatingBagde = styled.div`
  text-align: center;
  margin: 0.5em 0;
`

export const DeletedMessage = styled(Stack)`
  background-color: #e8e8e8;
  color: #a0a0a0;
  padding: 8px;
  margin: 2px 2px 8px;
`
