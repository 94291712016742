import { Carousel } from 'rsuite'
import { Attachment } from '@petshop/common'
import styled from 'styled-components'
import { useState } from 'react'

export const AttachmentCarousel = ({ attachments }: { attachments: Attachment[] }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Carousel
      activeIndex={activeIndex}
      onSelect={(index) => {
        setActiveIndex(index)
      }}
    >
      {attachments.map((attachment, i) => (
        <>
          {attachment.type.startsWith('image') && <AttachmentImage key={i} src={attachment.url} />}
          {attachment.type.startsWith('video') && (
            <video controls key={i}>
              <source src={attachment.url} />
            </video>
          )}
        </>
      ))}
    </Carousel>
  )
}

const AttachmentImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`