import React from 'react'
import { AxiosError, isAxiosError } from 'axios'
import { SessionService } from '../utils/session.utils.ts'

type ErrorHandler = (error: Error, info: React.ErrorInfo) => void
type ErrorHandlingComponent<Props> = (props: Props, error?: Error) => React.ReactNode

type ErrorState = { error?: Error }

export default function Catch<Props extends object>(
  component: ErrorHandlingComponent<Props>,
  errorHandler?: ErrorHandler
): React.ComponentType<Props> {
  return class extends React.Component<Props, ErrorState> {
    state: ErrorState = {
      error: undefined
    }

    static getDerivedStateFromError(error: Error) {
      return { error }
    }

    componentDidCatch(error: Error, info: React.ErrorInfo) {
      if (errorHandler) {
        errorHandler(error, info)
      }
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError
        switch (axiosError.response?.status) {
          case 401:
            SessionService.logout('Session expired. Please log in again.')
            break
        }
      }
    }

    render() {
      return component(this.props, this.state.error)
    }
  }
}
