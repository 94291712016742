import { z } from 'zod'

export const RatingSchema = z.object({
  user: z.string(),
  rating: z.number()
})

export const RatingRequestSchema = z.object({
  vote: z.enum(['up', 'down'])
})

export type RatingRequest = z.infer<typeof RatingRequestSchema>