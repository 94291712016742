import { z } from 'zod'
import { AttachmentSchema } from './attachment'

export const MessageBaseSchema = z.object({
  id: z.string().optional(),
  roomId: z.string(),
  userId: z.string(),
  user: z.string(),
  message: z.string(),
  rating: z.number().optional(),
  attachments: z.array(AttachmentSchema).optional(),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional(),
  deleted: z.boolean().optional(),
  voted: z.number().min(-1).max(1).optional()
})

export type MessageBase = z.infer<typeof MessageBaseSchema>