import { DragEvent, useState, FC, useRef } from 'react'
import styled from 'styled-components'
import { Stack } from 'rsuite'
import { TextLink } from './TextLink.tsx'
import { AiOutlineCloudUpload } from 'react-icons/ai'

type UploadAreaProps = {
  onFileSelected: (files: File[]) => void
  onError: (error: Error, files?: File[]) => void
  acceptMimeTypes?: `image/${string}`[]
  className?: string
}

export const UploadArea: FC<UploadAreaProps> = ({
  onFileSelected,
  onError,
  className,
  acceptMimeTypes = ['image/gif', 'image/jpg', 'image/jpeg', 'image/png', 'video/mp4', 'video/webm']
}) => {
  const [isOver, setIsOver] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const fileTypes = acceptMimeTypes.map((m) => m.replace('image/', '.')).join(', ')

  // Define the event handlers
  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(true)
  }

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(false)
  }

  const handleFileSelected = (files: File[]) => {
    if (files.every((f) => acceptMimeTypes.some((m) => m === f.type))) {
      onFileSelected(files)
    } else {
      onError(new Error('Wrong file type'), files)
    }
  }

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsOver(false)

    // Fetch the files
    const droppedFiles = Array.from(event.dataTransfer.files)
    handleFileSelected(droppedFiles)
  }

  const fileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if ((event.target.files?.length ?? 0) < 1) {
      onError(new Error('There were no file to upload.'))
      return
    }
    handleFileSelected(Array.from(event.target.files ?? []))
  }

  return (
    <DropArea onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop} $isOver={isOver} className={className}>
      <Stack direction="column">
        <AiOutlineCloudUpload size={40} />
        <Stack spacing="4px">
          <label>
            <HiddenInput ref={inputRef} type="file" accept={fileTypes} onChange={fileChange} multiple />
            <TextLink onClick={() => inputRef.current?.click()}>Click here to add file</TextLink> or drop here.
          </label>
        </Stack>
      </Stack>
    </DropArea>
  )
}

const DropArea = styled.div<{ $isOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px ${({ $isOver }) => ($isOver ? 'solid' : 'dashed')} #575757;
  background-color: ${({ $isOver }) => ($isOver ? '#d0d0d0' : 'white')};
  color: #575757;
  height: 200px;
`

const HiddenInput = styled.input`
  display: none;
  &:disabled + span {
    cursor: not-allowed;
    background-color: #e0e0e0;
  }
`
