import styled from 'styled-components'

export const PlainLink = styled.button`
  border: 0;
  padding: 0;
  line-height: 16px;
  background-color: transparent;
  text-align: left;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
`
