export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const getFileName = (url: string) => url.replace(/\?.*/, '').split('/').pop()

export const clearQueryParams = () => window.history.replaceState({}, document.title, window.location.href.split('?')[0])

export const unique = <T>(array: T[], propertyName: keyof T) => {
  return array.filter((e, i) => array.findIndex((a) => a[propertyName] === e[propertyName]) === i)
}

export const maxDateValue = (array: Date[]) => new Date(Math.max(...array.map((o) => o.getTime())))
