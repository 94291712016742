import { sleep } from '../utils/utils.ts'
import { useState } from 'react'

export type ChatMessage = {
  message: string
}

export type SystemMessageType = 'text' | 'options' | 'finish'

export type UserChatMessage = {
  sender: 'user'
} & ChatMessage

export type SystemChatMessage = {
  sender: 'system'
  options?: string[]
  validateAsync?: (message: string) => Promise<string | undefined>
  validateSync?: (message: string) => string | undefined
  field: string
  type: SystemMessageType
} & ChatMessage

type ChatServiceProps = {
  messages: SystemChatMessage[]
}

export function useChatService<T>({ messages }: ChatServiceProps) {
  const [index, setIndex] = useState(0)
  const [userMessages, setUserMessages] = useState<UserChatMessage[]>([])
  const [model, setModel] = useState<Partial<T>>({})
  const [errors, setErrors] = useState<Record<string, string | undefined>>({})

  const sendMessage = async (message: UserChatMessage): Promise<SystemChatMessage | undefined> => {
    if (index >= messages.length) {
      return
    }
    const systemMessage = messages[index]
    if (systemMessage.validateAsync) {
      const error = await systemMessage.validateAsync(message.message)
      if (error) {
        setErrors({ ...errors, [systemMessage.field]: error })
        return systemMessage
      }
    }
    setModel({ ...model, [systemMessage.field]: message.message })
    setUserMessages([...userMessages, message])
    const nextMessage = messages[index + 1]
    setIndex(index + 1)
    await sleep(2000)
    return nextMessage
  }

  /*const validateAll = async () => {
    for (const message of messages) {
      if (message.validateAsync) {
        const isValid = await message.validateAsync(model as T)
        if (!isValid) {
          return 'Validation failed'
        }
      }
    }
  }*/

  return {
    sendMessage,
    model,
    systemMessages: messages,
    errors
    // validateAll
  }
}
