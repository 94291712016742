import { RegisterModel } from '../models'
import axios from 'axios'
import {
  User,
  Thread,
  Room,
  Message,
  PrivateDiscussion,
  PrivateMessage,
  FriendRequest,
  Friends,
  Notification,
  Location,
  PublicUser
} from '@petshop/common'
import { ISLOCAL, API_BASE_URL } from '../constants/env.ts'
import { PresignedUrlResponse } from '@petshop/common/src/model/upload.ts'
import { SessionService } from '../utils/session.utils.ts'

export const postRegister = async (data: RegisterModel) => (await axios.post(`${API_BASE_URL}/register`, data)).data

export const isUsernameAvailable = async (username: string) =>
  (await axios.post<{ isAvailable: boolean }>(`${API_BASE_URL}/register/isAvailable`, { username })).data

export const login = async (username: string, token: string) => (await axios.post<User>(`${API_BASE_URL}/login`, { username, token })).data

export const createPresignedUrl = async (filename: string, mimetype: string, roomId?: string) => {
  const resp = (
    await axios.post<PresignedUrlResponse>(
      `${API_BASE_URL}/upload/signedurl`,
      {
        mimetype,
        roomId,
        filename
      },
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
  if (ISLOCAL && resp.uploadUrl.includes('localstack')) {
    // replace localstack with localhost for local development
    resp.uploadUrl = resp.uploadUrl.replace('localstack', 'localhost')
  }
  return resp
}

export const uploadAttachment = async (url: string, file: File) => {
  const response = await fetch(url, { method: 'PUT', body: file })
  if (!response.ok) {
    throw new Error(`Failed to upload attachment: ${response.status} ${response.statusText}`)
  }
}

export const getRooms = async () => {
  return (
    await axios.get<Room[]>(`${API_BASE_URL}/room`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getRoom = async (id: string) => {
  return (
    await axios.get<Room>(`${API_BASE_URL}/room/${id}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const putRoom = async (room: Room) => {
  return (
    await axios.put<Room>(`${API_BASE_URL}/room/${room.id}`, room, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getAllThreads = async (after?: number) => {
  return (
    await axios.get<Thread[]>(`${API_BASE_URL}/room/thread/all/${after}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getThreads = async (roomId: string, after?: number) => {
  return (
    await axios.get<Thread[]>(`${API_BASE_URL}/room/${roomId}/thread/byRoom/${after}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getThread = async (threadId: string, roomId: string) => {
  return (
    await axios.get<Thread>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const postThread = async (roomId: string, thread: Thread) => {
  return (
    await axios.post<Thread>(`${API_BASE_URL}/room/${roomId}/thread`, thread, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const putThread = async (roomId: string, threadId: string, thread: Thread) => {
  return (
    await axios.put<Thread>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}`, thread, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const voteThread = async (roomId: string, threadId: string, vote: 'up' | 'down') => {
  return (
    await axios.put<Thread>(
      `${API_BASE_URL}/room/${roomId}/thread/${threadId}/vote`,
      { vote },
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
}

export const getMessages = async (roomId: string, threadId: string, after?: number) => {
  return (
    await axios.get<Message[]>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/byThread/${after}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const postMessage = async (roomId: string, threadId: string, message: Message) => {
  return (
    await axios.post<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message`, message, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}
export const putMessage = async (roomId: string, threadId: string, message: Message) => {
  return (
    await axios.put<Message>(`${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/${message.id}`, message, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const voteMessage = async (roomId: string, threadId: string, messageId: string, vote: 'up' | 'down') => {
  return (
    await axios.put<Message>(
      `${API_BASE_URL}/room/${roomId}/thread/${threadId}/message/${messageId}/vote`,
      { vote },
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
}

export const getUser = async (userId: string) => {
  return (
    await axios.get<User>(`${API_BASE_URL}/user/${userId}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const fetchUsersByIds = async (ids: string[]) => {
  return (
    await axios.post<PublicUser[]>(
      `${API_BASE_URL}/user/list/byId`,
      { ids },
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
}

export const searchUsers = async (query: string) => {
  return (
    await axios.post<PublicUser[]>(`${API_BASE_URL}/user/search`, { query }, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const acceptFriendRequest = async (friendRequestId: string) => {
  return (
    await axios.post<Partial<User>[]>(
      `${API_BASE_URL}/friendrequest/accept/${friendRequestId}`,
      {},
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
}
export const removeFriendRequest = async (friendRequestId: string) => {
  return (
    await axios.delete<Partial<User>[]>(`${API_BASE_URL}/friendrequest/reject/${friendRequestId}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const putUser = async (userId: string, user: User) => {
  return (
    await axios.put<User>(`${API_BASE_URL}/user/${userId}`, user, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getPrivateDiscussion = async (selfUserId: string, receiverUserId: string) => {
  return (
    await axios.get<PrivateDiscussion>(`${API_BASE_URL}/chat/discussion/get/${selfUserId}/${receiverUserId}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}
export const getPrivateMessages = async (discussionId: string, after?: number) => {
  return (
    await axios.get<PrivateMessage[]>(`${API_BASE_URL}/chat/discussion/${discussionId}/message/byDiscussion/${after}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}
export const postPrivateMessage = async (discussionId: string, message: PrivateMessage) => {
  return (
    await axios.post<PrivateMessage>(`${API_BASE_URL}/chat/discussion/${discussionId}/message`, message, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

// Friendships API

export const listFriendRequests = async () => {
  return (
    await axios.get<FriendRequest[]>(`${API_BASE_URL}/friendrequest/list`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const checkFriendRequestSent = async (friendId: string) => {
  return (
    await axios.get<{ hasSent: boolean }>(`${API_BASE_URL}/friendrequest/hassent/${friendId}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const listFriends = async () => {
  return (
    await axios.get<Friends>(`${API_BASE_URL}/friends`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const sendFriendRequest = async (userId: string) => {
  return (
    await axios.post(
      `${API_BASE_URL}/friendrequest/send`,
      { to: userId },
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
}
export const removeFriendConnection = async (friendUserId: string) => {
  return (
    await axios.delete(`${API_BASE_URL}/friends/${friendUserId}`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getNotifications = async () => {
  return (
    await axios.get<Notification[]>(`${API_BASE_URL}/notification`, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const markNotificationAsRead = async (notificationId: string) => {
  return (
    await axios.put(`${API_BASE_URL}/notification/${notificationId}/read`, null, {
      headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
    })
  ).data
}

export const getClosestLocation = async (latitude: number, longitude: number) => {
  return (
    await axios.get<Location>(
      `${API_BASE_URL}/location/${latitude}/${longitude}`,
      {
        headers: { Authorization: `Bearer ${SessionService.loadSession()?.jwt}` }
      }
    )
  ).data
}