import { FC, useEffect, useState } from 'react'
import { SessionService } from '../utils/session.utils.ts'
import styled from 'styled-components'
import { Container, Header, Grid, Col, Row, InlineEdit, TagPicker, Input, Stack } from 'rsuite'
import { BsFillChatFill, BsCameraFill } from 'react-icons/bs'
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { PlainLink } from '../elements/PlainLink.tsx'
import { useNavigate, useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { friendRequestsAtom, friendsAtom, profileAtom, profileIdAtom } from '../atoms'
import { PrivateChat } from '../elements/user/PrivateChat.tsx'
import { createPresignedUrl, listFriendRequests, listFriends, putUser, uploadAttachment } from '../api'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { INTERESTS } from '../common/constants.tsx'
import { S3_URL } from '../constants/env.ts'
import { SendFriendRequestBtn } from '../elements/SendFriendRequestBtn.tsx'
import { ListFriendRequests } from '../elements/ListFriendRequests.tsx'
import { ListFriends } from '../elements/ListFriends.tsx'
import { FullpageContainer } from '../components/FullpageContainer.tsx'
import { MainContent } from '../elements/MainContent.tsx'

export const UserPage: FC = () => {
  const [, setProfileId] = useAtom(profileIdAtom)
  const [user, refreshUser] = useAtom(profileAtom)
  const [, setFriendRequests] = useAtom(friendRequestsAtom)
  const [friends, setFriends] = useAtom(friendsAtom)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [interests, setInterests] = useState<string[]>([])
  const [introduction, setIntroduction] = useState<string>('')
  const { userId } = useParams<{ userId: string }>()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()

  useEffect(() => {
    setProfileId(userId)
  }, [userId, setProfileId])

  useEffect(() => {
    setIntroduction(user?.introduction || '')
    setInterests(user?.interests || [])
  }, [user])

  useEffect(() => {
    Promise.all([listFriendRequests(), listFriends()])
      .then((res) => {
        const [friendRequest, friends] = res
        setFriendRequests(friendRequest)
        setFriends(friends)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [setFriendRequests, setFriends])

  if (!user) {
    return (
      <FullpageContainer>
        <h2>User not found</h2>
      </FullpageContainer>
    )
  }

  const saveUser = async () => {
    try {
      await putUser(user.id!, { ...user, interests, introduction })
      refreshUser()
      pushNotification('User saved', 'success')
    } catch (e) {
      pushNotification('Error saving user', 'error')
    }
  }

  const handleProfileImgUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const files = event.target.files as FileList
      const file = files[0]
      const response = await createPresignedUrl(file.name, file.type)
      await uploadAttachment(response.uploadUrl, file)
      const avatarUrl = `${S3_URL}/${response.fileId}`
      await putUser(user.id!, { ...user, avatarUrl })
      refreshUser()
    } catch (error) {
      console.error(error)
      pushNotification('Upload failed. Please try again.', 'error')
    }
  }

  const haveFriendsConnection = () => {
    if (friends && friends?.friendIds?.includes(user!.id as string)) return true

    return false
  }

  const interestsData = INTERESTS.map((interest) => ({
    label: interest,
    value: interest
  }))

  const isLoggedInUserProfile = SessionService.loadSession()?.id === user.id

  return (
    <Container>
      <Header>
        <Grid fluid>
          <Row>
            <Col xs={4} style={{ alignItems: 'center', display: 'flex', height: '42px' }}>
              <PlainLink onClick={() => navigate('/chat')}>
                <IoArrowBackCircleOutline size={32} />
              </PlainLink>
            </Col>
            <HeaderCol xs={16}>
              <h3>Profile</h3>
            </HeaderCol>
          </Row>
        </Grid>
      </Header>
      <MainContent>
        <Grid fluid>
          <Row>
            <MainCol xs={16} xsOffset={4}>
              <ProfileImage htmlFor="profile-img" $isLoggedInUserProfile={isLoggedInUserProfile} $avatarUrl={user.avatarUrl}>
                {isLoggedInUserProfile && <BsCameraFill size={24} />}
                <input onChange={handleProfileImgUpload} type="file" multiple={false} id="profile-img" hidden accept=".jpg,.jpeg,.png" />
              </ProfileImage>
            </MainCol>
          </Row>
          <Row>
            <MainCol xs={24}>
              <UserName>{user.username}</UserName>
            </MainCol>
          </Row>
          <Row>
            <MainCol xs={20} xsOffset={2} style={{ marginTop: '8px', marginBottom: '16px', textAlign: 'center' }}>
              {isLoggedInUserProfile ? (
                <InlineEdit
                  placeholder="Add a short introduction"
                  value={introduction}
                  size="lg"
                  onCancel={() => setIntroduction(user.introduction || '')}
                  onSave={() => saveUser()}
                  onChange={(e) => setIntroduction(e)}
                >
                  <Input as="textarea" rows={5} />
                </InlineEdit>
              ) : (
                <Stack>{user.introduction}</Stack>
              )}
            </MainCol>
          </Row>
          {!isLoggedInUserProfile && (
            <Row>
              <LeftButtonContainer xs={6}>
                {haveFriendsConnection() && (
                  <>
                    <LeftButton>
                      <PlainLink onClick={() => setOpenDrawer(true)}>
                        <BsFillChatFill size={36} />
                      </PlainLink>
                    </LeftButton>
                    Send a message
                  </>
                )}
              </LeftButtonContainer>
              <Col xs={12} />
              {<SendFriendRequestBtn />}
            </Row>
          )}
          <Row>
            <MainCol xs={20} xsOffset={2}>
              {isLoggedInUserProfile ? (
                <PillInlineEdit
                  placeholder="Choose your interests"
                  value={interests}
                  size="lg"
                  onCancel={() => setInterests(user.interests || [])}
                  onSave={() => saveUser()}
                  onChange={(e: string[]) => setInterests(e)}
                >
                  <TagPicker data={interestsData} block />
                </PillInlineEdit>
              ) : (
                <>{user.interests?.map((interest) => <Pill>{interest}</Pill>)}</>
              )}
            </MainCol>
          </Row>
        </Grid>
        {isLoggedInUserProfile && <ListFriends />}
        {isLoggedInUserProfile && <ListFriendRequests />}
      </MainContent>
      {openDrawer && <PrivateChat open={true} user={user} onClose={() => setOpenDrawer(false)} />}
    </Container>
  )
}

const HeaderCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonContainer = styled(Col)`
  display: flex;
  flex-direction: column;
`
const LeftButtonContainer = styled(ButtonContainer)`
  justify-content: flex-start;
  align-items: flex-start;
`
const LeftButton = styled.div`
  background-color: #fff1a7;
  height: 60px;
  width: 120px;
  border-radius: 0 35px 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e7bf27;
`

const Pill = styled(Stack)`
  background-color: #fff1a7;
  border-radius: 20px;
  padding: 8px 16px;
  margin: 8px;
  color: #e13737;
  height: 30px;
`
const PillInlineEdit = styled(InlineEdit)`
  --rs-text-primary: #e13737;
`

const UserName = styled.h2`
  font-weight: bold;
  margin-top: 16px;
  color: #e13737;
`

const ProfileImage = styled.label<{ $avatarUrl: string | undefined; $isLoggedInUserProfile: boolean }>`
  pointer-events: ${(props) => (props.$isLoggedInUserProfile ? 'all' : 'none')};
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: ${(props) => (props.$avatarUrl ? 'unset' : '#ccc')};
  background-image: ${(props) => (props.$avatarUrl ? `url(${props.$avatarUrl})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  & > svg {
    position: absolute;
    bottom: 17px;
    right: 20px;
    color: #e13737;
  }
`
