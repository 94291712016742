import { z } from 'zod'

export const UserSchema = z.object({
  id: z.string().optional(),
  token: z.string(),
  avatarUrl: z.string().optional(),
  active: z.boolean().optional(),
  isVerified: z.boolean().optional(),
  isBanned: z.boolean().optional(),
  username: z.string(),
  email: z.string().email(),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional(),
  introduction: z.string().optional(),
  interests: z.array(z.string()).optional(),
  friends: z.array(z.string()).optional(),
})

export type User = z.infer<typeof UserSchema>

export const PublicUserSchema = UserSchema.pick({
  id: true,
  avatarUrl: true,
  username: true
}).strict()
export type PublicUser = z.infer<typeof PublicUserSchema>
