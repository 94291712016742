import { useEffect, useState } from 'react'
import { ImHeart } from 'react-icons/im'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAtom } from 'jotai'
import { Col } from 'rsuite'
import { checkFriendRequestSent, removeFriendConnection, sendFriendRequest } from '../api'
import { useNotifications } from '../hooks/useNotifications'
import { friendRequestsAtom, friendsAtom, profileAtom } from '../atoms'
import { PlainLink } from './PlainLink'
import { SessionService } from '../utils/session.utils'
import { Friends } from '@petshop/common'

export const SendFriendRequestBtn = () => {
  const [friendRequests] = useAtom(friendRequestsAtom)
  const [friends, setFriends] = useAtom(friendsAtom)
  const [friendRequestSent, setFriendRequestSent] = useState(false)
  const [user] = useAtom(profileAtom)
  const { pushNotification } = useNotifications()
  useEffect(() => {
    if (user) {
      checkFriendRequestSent(user.id as string)
        .then((res) => {
          setFriendRequestSent(res.hasSent)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }, [user, setFriendRequestSent])

  const handleSendFriendRequest = async () => {
    try {
      await sendFriendRequest(user!.id as string)
      setFriendRequestSent(true)
      pushNotification('Request sent', 'success')
    } catch (error) {
      console.error(error)
      pushNotification('Error sending friend request', 'error')
    }
  }

  const handleRemoveFriend = async () => {
    try {
      await removeFriendConnection(user!.id as string)
      const newFriends = { ...friends } as Friends
      newFriends.friendIds = newFriends.friendIds.filter((id) => id !== user!.id)
      setFriends(newFriends)
      pushNotification('Removed from your friends', 'success')
    } catch (error) {
      console.error(error)
      pushNotification('Unfriend request failed. Pleas try again', 'error')
    }
  }

  const haveFriendsConnection = () => {
    if (friends && friends?.friendIds?.includes(user!.id as string)) return true

    return false
  }

  const hasFriendRequest = friendRequests.find((friendRequest) => friendRequest.from === user?.id)

  const renderFriendRequestElement = () => {
    if (haveFriendsConnection()) {
      return (
        <PlainLink onClick={handleRemoveFriend}>
          <ImHeartIcon filled={true} size={36} />
        </PlainLink>
      )
    }

    if (hasFriendRequest) {
      return (
        <Link to={`/user/${SessionService.loadSession()!.id}`}>
          <ImHeartIcon filled={friendRequestSent} size={36} />
        </Link>
      )
    }

    if (friendRequestSent) {
      return <ImHeartIcon filled={friendRequestSent} size={36} />
    }

    return (
      <PlainLink onClick={handleSendFriendRequest}>
        <ImHeartIcon filled={friendRequestSent} size={36} />
      </PlainLink>
    )
  }

  const renderFriendRequestLabel = () => {
    if (haveFriendsConnection()) {
      return 'Unfriend'
    }

    if (hasFriendRequest) {
      return 'Respond to Request'
    }

    if (friendRequestSent) {
      return 'Request Sent'
    }

    return 'Add to friends'
  }

  return (
    <RightButtonContainer xs={6}>
      <RightButton>
        {renderFriendRequestElement()}
        {/*<ImHeartBroken />*/}
      </RightButton>
      {renderFriendRequestLabel()}
    </RightButtonContainer>
  )
}

const ButtonContainer = styled(Col)`
  display: flex;
  flex-direction: column;
`

const RightButton = styled.div`
  background-color: #fff1a7;
  height: 60px;
  width: 120px;
  border-radius: 35px 0 0 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e7bf27;
`
const RightButtonContainer = styled(ButtonContainer)`
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
`
const ImHeartIcon = styled(ImHeart)<{ filled: boolean }>`
  color: ${(props) => (props.filled ? '#e13737' : '#e7bf27')};
`
