import { FC, ReactNode } from 'react'
import { useAtom } from 'jotai'
import { userAtom } from '../atoms'
import { useNavigate } from 'react-router-dom'
import { FullScreenMessage } from './FullScreenMessage.tsx'
import { TextLink } from './TextLink.tsx'

type Props = {
  children: ReactNode
}

export const AuthenticatedRoute: FC<Props> = ({ children }) => {
  const [user] = useAtom(userAtom)
  const navigate = useNavigate()

  if (!user) {
    return (
      <FullScreenMessage>
        You are not authenticated. Please click the link in your inbox or{' '}
        <TextLink onClick={() => navigate('/register')}>register</TextLink> to continue.
      </FullScreenMessage>
    )
  }

  return <>{children}</>
}
