import { z } from 'zod'

export const FriendsSchema = z.object({
  id: z.string().optional(),
  userId: z.string(),
  friendIds: z.array(z.string()),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional()
})

export type Friends = z.infer<typeof FriendsSchema>