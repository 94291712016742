import { z } from 'zod'

export const FriendRequestSchema = z.object({
  id: z.string().optional(),
  from: z.string(),
  to: z.string(),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional()
})

export type FriendRequest = z.infer<typeof FriendRequestSchema>