import { Suspense } from 'react'
import './App.less'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { RegisterPage } from './pages/Register.page.tsx'
import { LayoutTemplate } from './templates/Layout.template.tsx'
import { AuthenticatedRoute } from './elements/AuthenticatedRoute.tsx'
import { UserPage } from './pages/User.page.tsx'
import { FullpageError } from './components/FullpageError.tsx'
import { FullpageContainer } from './components/FullpageContainer.tsx'

function App() {
  return (
    <FullpageError>
      <BrowserRouter basename="/">
        <Suspense
          fallback={
            <FullpageContainer>
              <h4>Loading...</h4>
            </FullpageContainer>
          }
        >
          <Routes>
            <Route path="/register" element={<RegisterPage />} />
            <Route
              path="/chat/*"
              element={
                <AuthenticatedRoute>
                  <LayoutTemplate />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="/user/:userId"
              element={
                <AuthenticatedRoute>
                  <UserPage />
                </AuthenticatedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/chat" replace />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </FullpageError>
  )
}

export default App
