import styled from 'styled-components'

export const LoadedMessagesElement = styled.button`
  position: sticky;
  background-color: #FFEC84;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`