import { Container, Stack, Button, Col, Grid, Row, Form, Schema } from 'rsuite'
import styled from 'styled-components'
import logo from '../images/pepper.png'
import loadingBar from '../images/loading.gif'
import { SystemChatMessage, UserChatMessage, useChatService } from '../services/ChatService.ts'
import { useState, useEffect, useRef } from 'react'
import { sleep } from '../utils/utils.ts'
import { FooterElement } from '../elements/FooterMenu.tsx'
import { RegisterModel, RegisterModelSchema } from '../models'
import { postRegister, isUsernameAvailable } from '../api'
import { useNavigate } from 'react-router-dom'
import { Message, MessageContainer, InputWithError, ErrorMessage } from '../elements/chat/MessageElements.tsx'
import { MainContent } from '../elements/MainContent.tsx'

export const RegisterPage = () => {
  const [messages, setMessages] = useState<(SystemChatMessage | UserChatMessage)[]>([])

  const { sendMessage, systemMessages, model, errors } = useChatService<RegisterModel>({
    messages: [
      {
        message: 'Hi there! What should I call you?',
        sender: 'system',
        field: 'username',
        type: 'text',
        validateSync: (message: string) => (message.length < 3 ? 'Username must be longer than 3 letters.' : undefined),
        validateAsync: async (message: string) => {
          const response = await isUsernameAvailable(message)
          return response.isAvailable ? undefined : 'Username is already taken.'
        }
      },
      {
        message: 'We need your email address for future logins. Could you give it?',
        sender: 'system',
        field: 'email',
        type: 'text',
        validateSync: (message: string) =>
          message.length < 1 || Schema.Types.StringType().isEmail('Value is not email.').check(message).hasError
            ? 'Email address is incorrect'
            : undefined
      },
      {
        message: 'Thanks! Check out your email for a login link. You can close this window now.',
        sender: 'system',
        type: 'finish',
        field: 'finish'
      }
    ]
  })
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [lastSystemMessage, setLastSystemMessage] = useState<SystemChatMessage | undefined>(undefined)
  const messageRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      setLoading(true)
      messageRef.current?.focus()
      await sleep(1000)
      setLastSystemMessage(systemMessages[0])
      setMessages([systemMessages[0]])
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const valid = RegisterModelSchema.safeParse(model)
    if ((!lastSystemMessage || lastSystemMessage?.type === 'finish') && valid.success) {
      postRegister(model as RegisterModel).then(() => {
        navigate('/chat')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSystemMessage])

  const scrollDown = () => {
    setTimeout(() => {
      window.scrollTo({
        top: window.outerHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const sendServiceMessage = async (message: string) => {
    try {
      setLoading(true)

      const userMessage: UserChatMessage = { message, sender: 'user' }
      const msgs = [...messages, userMessage]
      setMessages(msgs)
      scrollDown()
      const msg = await sendMessage({
        message,
        sender: 'user'
      })
      setMessage('')
      setLastSystemMessage(msg)
      messageRef.current?.focus()
      if (msg) {
        setMessages([...msgs, msg])
      }
      scrollDown()
    } finally {
      setLoading(false)
    }
  }

  const setAndValidateMessage = (message: string) => {
    setMessage(message)
    if (lastSystemMessage?.validateSync) {
      errors[lastSystemMessage.field as keyof RegisterModel] = lastSystemMessage.validateSync(message)
    }
  }

  return (
    <Container>
      <RegisterMainContent>
        <CenterContainer justifyContent="center">
          <Logo src={logo} />
        </CenterContainer>
        {messages.map((message, index) => (
          <MessageContainer flow={message.sender === 'system' ? 'left' : 'right'} direction="column" key={`message_${index}`}>
            <Message>{message.message}</Message>
          </MessageContainer>
        ))}
        {loading && (
          <MessageContainer flow="left" direction="column">
            <Message>
              <LoadingBar src={loadingBar} />
            </Message>
          </MessageContainer>
        )}
      </RegisterMainContent>
      <RegisterFooter>
        <Grid fluid style={{ margin: '10px 0' }}>
          {lastSystemMessage?.type === 'options' && (
            <MessageContainer flow="center" direction="row">
              {lastSystemMessage.options?.map((option: string) => (
                <Message
                  onClick={async () => {
                    if (lastSystemMessage === messages[messages.length - 1]) {
                      await sendServiceMessage(option)
                    }
                  }}
                  key={option}
                >
                  {option}
                </Message>
              ))}
            </MessageContainer>
          )}
          {lastSystemMessage?.type === 'text' && (
            <Form
              onSubmit={() => sendServiceMessage(message)}
              disabled={!message || (lastSystemMessage && !!errors[lastSystemMessage.field])}
            >
              <Row>
                <Col md={16} xs={16} mdOffset={1} xsOffset={1}>
                  <InputWithError
                    name="message"
                    onChange={setAndValidateMessage}
                    disabled={loading}
                    value={message}
                    ref={messageRef}
                    className={lastSystemMessage && !!errors[lastSystemMessage.field] ? 'error' : ''}
                  />
                  {!loading && lastSystemMessage && !!errors[lastSystemMessage.field] && (
                    <ErrorMessage>{errors[lastSystemMessage.field]}</ErrorMessage>
                  )}
                </Col>
                <Col md={4} xs={4} mdPush={2} xsPush={2}>
                  <Button
                    appearance="primary"
                    type="submit"
                    disabled={!message || (lastSystemMessage?.validateSync && !!lastSystemMessage.validateSync(message))}
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
          {lastSystemMessage?.type === 'finish' && <></>}
        </Grid>
      </RegisterFooter>
    </Container>
  )
}

const Logo = styled.img`
  width: 300px;
`

const CenterContainer = styled(Stack)`
  display: flex;
  width: 100%;
`
const LoadingBar = styled.img`
  height: 12px;
`

const RegisterMainContent = styled(MainContent)`
  margin-bottom: 65px;
`
const RegisterFooter = styled(FooterElement)`
  box-shadow: 0 -5px 5px -5px #999;
`
