import { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { acceptFriendRequest, fetchUsersByIds, removeFriendRequest } from '../api'
import { Button, FlexboxGrid, Grid, Row, Col, Container } from 'rsuite'
import { User } from '@petshop/common'
import { useNotifications } from '../hooks/useNotifications'
import { useAtom } from 'jotai'
import { friendRequestsAtom } from '../atoms'

export const ListFriendRequests = () => {
  const [requests] = useAtom(friendRequestsAtom)
  const [users, setUsers] = useState<Partial<User>[]>([])
  const { pushNotification } = useNotifications()

  useLayoutEffect(() => {
    if (requests.length) {
      const ids = requests.map((req) => req.from)
      fetchUsersByIds(ids).then((users) => {
        setUsers(users)
      })
    }
  }, [requests])

  const handleConfirmRequest = async (userId: string) => {
    try {
      const userRequest = requests.find((req) => req.from === userId)
      await acceptFriendRequest(userRequest?.id as string)
      pushNotification('You are now friend', 'success')
      setUsers((prev) => {
        return [...prev].filter((user) => user.id !== userId)
      })
    } catch (error) {
      pushNotification('Failed to confirm accept request', 'error')
    }
  }

  const handleRejectRequest = async (userId: string) => {
    try {
      const userRequest = requests.find((req) => req.from === userId)
      await removeFriendRequest(userRequest?.id as string)
      pushNotification('Request Removed', 'success')
      setUsers((prev) => {
        return [...prev].filter((user) => user.id !== userId)
      })
    } catch (error) {
      pushNotification('Failed to remove request', 'error')
    }
  }

  if (users.length === 0) {
    return null
  }

  return (
    <FriendRequestMainContainer>
      <FriendRequestTitleContainer>
        <h4>Friend Requests</h4>
        <FriendRequestNum>({users.length})</FriendRequestNum>
      </FriendRequestTitleContainer>

      <FriendRequestsListContainer fluid>
        {users.map((user) => (
          <Row>
            <Col xs={24}>
              <FlexboxGrid
                style={{
                  alignItems: 'center',
                  gap: '20px'
                }}
              >
                <FlexboxGrid.Item>
                  <UserAvatar avatarUrl={user.avatarUrl} />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item>
                  <h4>{user.username}</h4>
                  <FlexboxGrid style={{ gap: '4px' }}>
                    <FlexboxGrid.Item>
                      <Button onClick={() => handleConfirmRequest(user.id!)} appearance="primary" color="blue">
                        Confirm
                      </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item>
                      <Button onClick={() => handleRejectRequest(user.id!)} appearance="primary" color="red">
                        Delete
                      </Button>
                    </FlexboxGrid.Item>
                  </FlexboxGrid>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </Col>
          </Row>
        ))}
      </FriendRequestsListContainer>
    </FriendRequestMainContainer>
  )
}

const FriendRequestMainContainer = styled(Container)`
  max-width: 880px;
  padding: 0 12px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
`

const FriendRequestsListContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 20px 0;
`

const FriendRequestTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

const FriendRequestNum = styled.span`
  font-size: 20px;
  display: inline-block;
  font-weight: bold;
  color: #e13737;
`

const UserAvatar = styled.div<{ avatarUrl: string | undefined }>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${(props) => (props.avatarUrl ? 'unset' : '#ccc')};
  background-image: ${(props) => (props.avatarUrl ? `url(${props.avatarUrl})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
`
