import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Chat } from '../pages/Chat'
import { ChatThread } from '../pages/ChatThread'

export const ChatTemplate: FC = () => (
  <>
    <Routes>
      <Route path="/" element={<Chat />} />
      <Route path="/:roomId" element={<Chat />} />
      <Route path="/:roomId/thread/:threadId" element={<ChatThread />} />
    </Routes>
  </>
)
