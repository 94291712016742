import { ReactNode, FC } from 'react'
import { Container } from 'rsuite'
import { MainContent } from '../elements/MainContent.tsx'
import styled from 'styled-components'

type Props = {
  children: ReactNode
}

export const FullpageContainer: FC<Props> = ({ children }) => {
  return (
    <Container>
      <CenteredMainContent>{children}</CenteredMainContent>
    </Container>
  )
}

const CenteredMainContent = styled(MainContent)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
