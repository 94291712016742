import React, { useEffect, useState, useCallback } from 'react'
import { useAtom } from 'jotai'
import { useParams, useNavigate } from 'react-router-dom'
import {
  messagesAtom,
  threadIdAtom,
  roomsAtom,
  roomIdAtom,
  messagesAfterAtom,
  threadAtom
} from '../atoms'
import { ChatCard } from '../elements/ChatCard'
import { ThreadMessageCard } from '../elements/ThreadMessageCard'
import { MainContainer } from '../elements/MainContainer'
import { ChatModal } from '../elements/ChatModal'
import { PlainLink } from '../elements/PlainLink.tsx'
import { FaReply } from 'react-icons/fa'
import { postMessage, putThread, getMessages } from '../api'
import { SessionService } from '../utils/session.utils.ts'
import { ChatMessageActions, ChatMessageActionContainer } from '../elements/ChatMessageActions.tsx'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { ChatModalMessage } from '../types'
import { DeletedMessage } from '../elements/ChatCardElements.tsx'
import { Modal, Button } from 'rsuite'
import { Thread, Message } from '@petshop/common'
import { MdDeleteOutline } from 'react-icons/md'
import { unique, maxDateValue } from '../utils/utils.ts'
import { LoadedMessagesElement } from '../elements/LoadedMessagesElement.tsx'
import { useAtomCallback } from 'jotai/utils'
import { FullpageContainer } from '../components/FullpageContainer.tsx'

export const ChatThread: React.FC = () => {
  const { roomId, threadId } = useParams<{ roomId: string; threadId: string }>()
  const [rooms] = useAtom(roomsAtom)
  const [thread] = useAtom(threadAtom)
  const [, setRoomId] = useAtom(roomIdAtom)
  const [, setThreadId] = useAtom(threadIdAtom)
  const [, setAfter] = useAtom(messagesAfterAtom)
  const [messages, setMessages] = useAtom(messagesAtom)
  const [loadedMessages, setLoadedMessages] = useState<Message[]>([])
  const reloadMessages = useAtomCallback(useCallback(async (get) => {
    const after = get(messagesAfterAtom).getTime()
    const loaded = await getMessages(roomId!, threadId!, after)
    setLoadedMessages(loaded)
    return loaded
  }, [roomId, threadId]))
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const { pushNotification } = useNotifications()
  const navigate = useNavigate()

  const loadMessages = async () => {
    const loaded = await reloadMessages()
    setLoadedMessages([])
    setMessages(unique([...messages, ...loaded], 'id'))
    setLoading(false)
  }

  useEffect(() => {
    setRoomId(roomId)
    setThreadId(threadId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId, threadId])

  useEffect(() => {
    const timeout = setInterval(async () => {
      await reloadMessages()
    }, 10000)
    void loadMessages()
    return () => {
      clearInterval(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (messages && messages.length) {
      setAfter(maxDateValue(messages.map((t) => new Date(t.updated!))))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  const room = rooms.find((c) => c.id === roomId)

  if (loading) {
    return (<FullpageContainer>
      <h4>Loading...</h4>
    </FullpageContainer>)
  }

  if (!thread || !room) {
    return <div>Thread not found</div>
  }

  if (thread.deleted) {
    return <DeletedMessage>This message has been deleted.</DeletedMessage>
  }

  const saveReply = async (thread: ChatModalMessage) => {
    try {
      const newMessage = await postMessage(thread.roomId, threadId!, {
        ...thread,
        threadId: threadId!,
        userId: SessionService.loadSession()!.id!,
        user: SessionService.loadSession()!.username
      })
      setMessages(unique([...messages, ...loadedMessages, newMessage], 'id'))
      pushNotification('Message sent', 'success')
    } catch (e) {
      pushNotification('Failed to send message', 'error')
    }
  }

  const deleteThread = async (thread: Thread) => {
    try {
      await putThread(thread.roomId, thread.id!, {
        ...thread,
        deleted: true
      })
      pushNotification('Message deleted', 'success')
      navigate('/chat')
    } catch (e) {
      pushNotification('Failed to delete message', 'error')
    }
  }

  const onLoadedMessagesClick = () => {
    setMessages(unique([...messages, ...loadedMessages], 'id'))
  }

  const messageActions = (parentMessageId: string) => {
    return <ChatMessageActions messageId={parentMessageId} afterSubmit={(msg) => {
      setMessages(unique([msg, ...messages, ...loadedMessages], 'id'))
    }} />
  }

  const threadActions = () => {
    return (
      <ChatMessageActionContainer spacing={20}>
        <PlainLink onClick={() => setShowModal(true)}>
          <FaReply size={16} />
        </PlainLink>
        {thread && thread.user === SessionService.loadSession()?.username && (
          <PlainLink onClick={() => setShowDeleteAlert(true)}>
            <MdDeleteOutline size={16} />
          </PlainLink>
        )}
      </ChatMessageActionContainer>
    )
  }

  const filteredMessages = messages.filter((rm) => !rm.parentId)

  return (
    <MainContainer>
      {loadedMessages.length > 0 && (
        <LoadedMessagesElement onClick={onLoadedMessagesClick}>
          {loadedMessages.length} new messages loaded. Click to show.
        </LoadedMessagesElement>
      )}
      <ChatCard room={room} thread={thread} actions={threadActions()} />
      {filteredMessages.map((tm) => (
        <ThreadMessageCard
          thread={thread}
          message={tm}
          remainingMessages={messages}
          level={1}
          key={`thread_${tm.id}`}
          cardStyle={{ backgroundColor: room.colors?.background }}
          actions={messageActions(tm.id!)}
          reloadMessages={loadMessages}
        />
      ))}
      <ChatModal
        show={showModal}
        predefinedRoomId={room.id}
        setShow={setShowModal}
        onSubmit={async (message) => {
          await saveReply(message)
          setShowModal(false)
        }}
      />
      <Modal open={showDeleteAlert} onClose={() => setShowDeleteAlert(false)} role="alertdialog" size="xs">
        <Modal.Body>Are you sure to delete message?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowDeleteAlert(false)} appearance="subtle">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await deleteThread(thread)
              setShowDeleteAlert(false)
            }}
            appearance="primary"
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  )
}
