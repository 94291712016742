import { useAtom } from 'jotai'
import styled from 'styled-components'
import { friendsAtom } from '../atoms'
import { Container } from 'rsuite'
import { useLayoutEffect, useState } from 'react'
import { PublicUser } from '@petshop/common'
import { fetchUsersByIds } from '../api'
import { UserAvatar } from "./user/UserAvatar.tsx";

export const ListFriends = () => {
  const [friends] = useAtom(friendsAtom)
  const [users, setUsers] = useState<PublicUser[]>([])

  useLayoutEffect(() => {
    if (friends?.friendIds?.length) {
      fetchUsersByIds(friends.friendIds).then((users) => {
        setUsers(users)
      })
    }
  }, [friends])

  if (!friends) {
    return null
  }

  return (
    <FriendsContainer>
      <FriendRequestTitleContainer>
        <h4>Friends</h4>
        <FriendRequestNum>({friends?.friendIds?.length})</FriendRequestNum>
      </FriendRequestTitleContainer>
      <FriendListContainer>
        {users.map((user) => (
          <UserAvatar user={user} />
        ))}
      </FriendListContainer>
    </FriendsContainer>
  )
}

const FriendsContainer = styled(Container)`
  max-width: 880px;
  padding: 0 12px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
`

const FriendListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin: 14px 0;
`

const FriendRequestTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

const FriendRequestNum = styled.span`
  font-size: 20px;
  display: inline-block;
  font-weight: bold;
  color: #e13737;
`
