import { useState, useEffect, RefCallback } from 'react'
import {
  Container,
  Header,
  Grid,
  Row,
  Col,
  Button,
  Dropdown,
  Popover,
  Whisper,
  IconButton,
  Badge,
  Modal
} from 'rsuite'
import { HiMenu } from 'react-icons/hi'
import styled from 'styled-components'
import { FooterElement } from '../elements/FooterMenu.tsx'
import { ChatTemplate } from './Chat.template.tsx'
import { SessionService } from '../utils/session.utils.ts'
import { BiLogOut } from 'react-icons/bi'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { roomsAtom, roomIdAtom, notificationsAtom, locationAtom, coordinatesAtom } from '../atoms'
import { useNavigate } from 'react-router-dom'
import { CgProfile } from 'react-icons/cg'
import { MdNotifications } from 'react-icons/md'
import { TextLink } from '../elements/TextLink.tsx'
import { Notification } from '@petshop/common'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { markNotificationAsRead } from '../api'
import { MainContent } from '../elements/MainContent.tsx'

export const LayoutTemplate = () => {
  const location = useAtomValue(locationAtom)
  const setCoordinates = useSetAtom(coordinatesAtom)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [rooms] = useAtom(roomsAtom)
  const [roomId] = useAtom(roomIdAtom)
  const [notifications, refreshNotifications] = useAtom(notificationsAtom)
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCoordinates({ longitude: position.coords.longitude, latitude: position.coords.latitude })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentRoom = rooms.find((room) => room.id === roomId)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const renderMenu = ({ onClose, left, top, className }, ref: RefCallback<HTMLElement>) => {
    const getNotificationIcon = () => {
      if (notifications.length) {
        return <Badge content={notifications.length}><MdNotifications /></Badge>
      }
      return <MdNotifications />
    }

    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu
          onSelect={(eventKey) => {
            if (eventKey === 'profile') {
              navigate(`/user/${SessionService.loadSession()?.id}`)
            } else if (eventKey === 'logout') {
              SessionService.logout('You have been logged out!')
            } else if (eventKey === 'notifications') {
              setShowNotificationModal(true)
            }
            onClose()
          }}
        >
          <Dropdown.Item eventKey="profile" icon={<CgProfile />}>
            {' '}
            Profile
          </Dropdown.Item>
          <Dropdown.Item eventKey="notifications" icon={getNotificationIcon()}>
            {' '}
            Notifications
          </Dropdown.Item>
          <Dropdown.Item eventKey="logout" icon={<BiLogOut />}>
            {' '}
            Log out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    )
  }

  const getMenuIcon = () => {
    if (notifications.length) {
      return (<Badge><HiMenu size={40} /></Badge>)
    }
    return <HiMenu size={40} />
  }

  const markNotificationRead = async (notification: Notification) => {
    try {
      await markNotificationAsRead(notification.id!)
      refreshNotifications()
    } catch (e) {
      pushNotification('Failed to mark notification as read', 'error')
    }
  }

  return (
    <Container>
      <HeaderElement>
        <Grid fluid>
          <HeaderRow>
            <HeaderCol xs={4} style={{ justifyContent: 'flex-start' }}>
              <Whisper placement="bottomStart" trigger="click" speaker={renderMenu as never}>
                <IconButton icon={getMenuIcon()} appearance="subtle" />
              </Whisper>
            </HeaderCol>
            <HeaderCol xs={16} style={{ justifyContent: 'center' }}>
              {location ? location.city : ''}
            </HeaderCol>
            <HeaderCol xs={4} style={{ justifyContent: 'flex-end' }}>
              <Dropdown
                title={currentRoom?.name || 'All rooms'}
                onSelect={(eventKey) => {
                  if (eventKey === 'allRooms') {
                    navigate('/chat')
                  } else {
                    navigate(`/chat/${eventKey}`)
                  }
                }}
              >
                <Dropdown.Item eventKey="allRooms">All rooms</Dropdown.Item>
                {rooms.map((room) => (
                  <Dropdown.Item key={room.id} eventKey={room.id}>
                    {room.name}
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </HeaderCol>
          </HeaderRow>
        </Grid>
      </HeaderElement>
      <MainContent>
        <ChatTemplate />
      </MainContent>
      <FooterElement>
        <Grid fluid>
          <Row>
            <FooterCol xs={8}>
              <Button appearance="link" onClick={() => navigate('/chat')}>
                Chat
              </Button>
            </FooterCol>
            <FooterCol xs={8}>
              <Button appearance="link" onClick={() => navigate(`/user/${SessionService.loadSession()?.id}`)}>
                Me
              </Button>
            </FooterCol>
            <FooterCol xs={8}>
              <Button appearance="link" disabled>
                Events
              </Button>
            </FooterCol>
          </Row>
        </Grid>
      </FooterElement>
      <Modal open={showNotificationModal} onClose={() => setShowNotificationModal(false)}>
        <Modal.Header>
          <Modal.Title>Notifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notifications.length === 0 && <div>No new notifications</div>}
          {notifications.map((notification, i) => (
            <NotificationContainer $odd={i % 2 !== 0} fluid>
              <Row>
                <Col xs={2}>{notification.read ? '' : <Badge content="NEW" />}</Col>
                <Col xs={20}>
                  <h5>{notification.title}</h5>
                  <div key={notification.id}>{notification.message}</div>
                </Col>
                <Col xs={2}>
                  <TextLink onClick={async () => await markNotificationRead(notification)}>Mark as read</TextLink>
                </Col>
              </Row>
            </NotificationContainer>
          ))}
        </Modal.Body>
      </Modal>
    </Container>
  )
}
const HeaderElement = styled(Header)`
  box-shadow: 0 2px 5px -2px #888888;
`
const HeaderRow = styled(Row)`
  display: flex;
`
const HeaderCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin: 0 1%;
  padding: 4px;
`
const FooterCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #e13737;
  border-left: 1px solid white;
  border-right: 1px solid white;
  height: 50px;
  color: white;
  font-size: 1.25em;
`
const NotificationContainer = styled(Grid)<{ $odd: boolean }>`
  background-color: ${({ $odd }) => ($odd ? 'white' : '#f0f0f0')};
  padding: 8px;
`