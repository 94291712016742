import Catch from './FunctionalErrorBoundary'
import { ReactNode, Fragment } from 'react'
import { FullpageContainer } from './FullpageContainer'

type Props = {
  children: ReactNode
}

export const FullpageError = Catch(function MyErrorBoundary(props: Props, error?: Error) {
  if (error) {
    return (
      <FullpageContainer>
        <h2>An error has occured</h2>
        <h4>{error.message}</h4>
      </FullpageContainer>
    )
  } else {
    return <Fragment>{props.children}</Fragment>
  }
})
