import styled from 'styled-components'
import React from 'react'
import { BsPlusCircleFill } from 'react-icons/bs'
import { PlainLink } from './PlainLink'

type Props = {
  onClick: () => void
}

export const AddButton: React.FC<Props> = ({ onClick }) => (
  <AddContainer>
    <PlainLink onClick={onClick}>
      <BsPlusCircleFill size={70} style={{ fill: '#E13737' }} />
    </PlainLink>
  </AddContainer>
)

const AddContainer = styled.span`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 40px;
`
