import styled from 'styled-components'
import { PlainLink } from './PlainLink.tsx'

export const TextLink = styled(PlainLink)`
  color: #e85c46;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
