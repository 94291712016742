import { User } from '@petshop/common'
import { AuthenticatedUser } from '../types'

const AUTH_SESSION_KEY = 'petshop-user'

export const SessionService = {
  loadSession(): AuthenticatedUser | undefined {
    const payload = sessionStorage.getItem(AUTH_SESSION_KEY)
    return payload ? JSON.parse(payload) : undefined
  },
  clearSession() {
    sessionStorage.removeItem(AUTH_SESSION_KEY)
  },
  saveSession(newUser: User) {
    sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(newUser))
  },
  logout(message?: string) {
    this.clearSession()
    window.location.href = '/login'
    if (message) {
      window.sessionStorage.setItem('logout-message', message)
    }
  }
}
