import { PublicUser } from "@petshop/common"
import { FC, useEffect, useState } from "react"
import { Modal, Grid, Row, Heading, Text, Button } from "rsuite"
import { useAtom } from "jotai";
import { usersAtom, roomAtom } from "../atoms";
import { SessionService } from '../utils/session.utils.ts'
import { UserAvatar } from './user/UserAvatar.tsx'
import { UserAutocomplete } from './UserAutocomplete.tsx';
import { putRoom } from '../api';
import { useNotifications } from '../hooks/useNotifications.tsx';
import styled from 'styled-components';

type Props = {
    open: boolean
    roomId: string
    onClose: () => void | Promise<void>
}

export const RoomAdminModal: FC<Props> = ({ open, roomId, onClose }) => {
  const [users, setUserIds] = useAtom(usersAtom)
  const [room, refreshRoom] = useAtom(roomAtom)
  const [newAdmin, setNewAdmin] = useState<string | undefined>()
  const [newMember, setNewMember] = useState<string | undefined>()
  const { pushNotification } = useNotifications()

  useEffect(() => {
    refreshRoom()
  }, [roomId])

  useEffect(() => {
    if (!room) {
      return
    }
    const userIds = room.members || []
    if (isOwner && room.admins) {
      userIds.push(...room.admins)
    }
    void setUserIds(userIds)
  }, [room])

  if (!room) {
    return <></>
  }

  const addAdmin = async () => {
    const updatedRoom =  { ...room, admins: [...(room.admins || []), newAdmin!] }
    try {
      await putRoom(updatedRoom)
      refreshRoom()
      setNewAdmin(undefined)
      pushNotification('Room updated.', 'success')
    } catch (e) {
      pushNotification('Room update failed.', 'error')
    }
  }

  const removeAdmin = async (userId: string) => {
    const updatedRoom =  { ...room, admins: room.admins?.filter((id) => id !== userId) }
    try {
      await putRoom(updatedRoom)
      refreshRoom()
      pushNotification('Room updated.', 'success')
    } catch (e) {
      pushNotification('Room update failed.', 'error')
    }
  }

  const addMember = async () => {
    const updatedRoom = { ...room, members: [...(room.members || []), newMember!] }
    try {
      await putRoom(updatedRoom)
      refreshRoom()
      setNewMember(undefined)
      pushNotification('Room updated.', 'success')
    } catch (e) {
      pushNotification('Room update failed.', 'error')
    }
  }

  const removeMember = async (userId: string) => {
    const updatedRoom = { ...room, members: room.members?.filter((id) => id !== userId) }
    try {
      await putRoom(updatedRoom)
      refreshRoom()
      pushNotification('Room updated.', 'success')
    } catch (e) {
      pushNotification('Room update failed.', 'error')
    }
  }

  const isOwner = SessionService.loadSession()?.id === room.owner
  const isAdmin = (user: PublicUser) => room.admins?.includes(user.id!)
  const isMember = (user: PublicUser) => room.members?.includes(user.id!)

  const admins = users.filter(isAdmin)
  const members = users.filter(isMember)

	return (
		<Modal open={open} onClose={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Room administration for "{room.name}"</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isOwner && (
          <>
          <ModalGrid fluid>
            <Row>
              <Heading>Admins</Heading>
            </Row>
            <ModalListContainer>
              {admins.map((u) => (
                <UserAvatar user={u} size='small' actions={<Button appearance='subtle' color='red' onClick={() => removeAdmin(u.id!)}>Remove</Button>} />
              ))}
              {admins.length === 0 && <Text>No admins</Text>}
            </ModalListContainer>
          </ModalGrid>
          <ModalGrid fluid>
            <Row>
              Add more admins (use exact user name): <UserAutocomplete onChosen={setNewAdmin} />
              <Button onClick={() => addAdmin()} disabled={!newAdmin}>Add admin</Button>
            </Row>
          </ModalGrid>
          </>
        )}
        <ModalGrid fluid>
          <Row>
            <Heading>Members</Heading>
          </Row>
          <ModalListContainer>
            {members.map((u) => <UserAvatar user={u} size='small' actions={<Button appearance='subtle' color='red' onClick={() => removeMember(u.id!)}>Remove</Button>} />)}
            {members.length === 0 && <Text>No members</Text>}
          </ModalListContainer>
        </ModalGrid>
        <ModalGrid fluid>
          <Row>
            Add more members (use exact user name): <UserAutocomplete onChosen={setNewMember} />
            <Button onClick={() => addMember()} disabled={!newMember}>Add member</Button>
          </Row>
        </ModalGrid>
      </Modal.Body>
    </Modal>
  )
}

const ModalGrid = styled(Grid)`
  margin-bottom: 1rem;
`
const ModalListContainer = styled(Row)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  margin: 14px 0;
`