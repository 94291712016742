import { FC, useEffect, useState, useCallback } from 'react'
import { useAtom } from 'jotai'
import { threadsAtom, roomsAtom, roomIdAtom, threadsAfterAtom } from '../atoms'
import { ChatCard } from '../elements/ChatCard'
import { MainContainer } from '../elements/MainContainer'
import { AddButton } from '../elements/AddButton'
import { ChatModal } from '../elements/ChatModal'
import { useParams, useNavigate } from 'react-router-dom'
import { Thread } from '@petshop/common'
import { postThread, putThread, getAllThreads, getThreads } from '../api'
import { SessionService } from '../utils/session.utils.ts'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { ChatMessageActionContainer } from '../elements/ChatMessageActions.tsx'
import { PlainLink } from '../elements/PlainLink.tsx'
import { MdDeleteOutline } from 'react-icons/md'
import { Modal, Button } from 'rsuite'
import { unique, maxDateValue } from '../utils/utils.ts'
import { LoadedMessagesElement } from '../elements/LoadedMessagesElement.tsx'
import { useAtomCallback } from 'jotai/utils'
import dayjs from 'dayjs'
import { EditRoomButton } from '../elements/EditRoomButton.tsx'
import { RoomAdminModal } from '../elements/RoomAdminModal.tsx';

export const Chat: FC = () => {
  const [threads, setThreads] = useAtom(threadsAtom)
  const [loadedThreads, setLoadedThreads] = useState<Thread[]>([])
  const reloadThreads = useAtomCallback(useCallback(async (get) => {
    const roomId = get(roomIdAtom)
    const after = get(threadsAfterAtom)
    let threads: Thread[] = []
    if (!roomId) {
      threads = await getAllThreads(after.getTime())
    } else {
      threads = await getThreads(roomId, after.getTime())
    }
    setLoadedThreads(threads)
    return threads
  }, []))
  const [, setRoomId] = useAtom(roomIdAtom)
  const [rooms] = useAtom(roomsAtom)
  const [, setAfter] = useAtom(threadsAfterAtom)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showAdminModal, setShowAdminModal] = useState<boolean>(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false)
  const [deletableThread, setDeletableThread] = useState<Thread>()
  const { roomId } = useParams<{ roomId: string }>()
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()

  useEffect(() => {
    setRoomId(roomId)
    setAfter(dayjs().subtract(14, 'days').toDate())
    reloadThreads().then((threads) => {
      setThreads([...loadedThreads, ...threads])
      setLoadedThreads([])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId])

  useEffect(() => {
    const timeout = setInterval(async () => {
      await reloadThreads()
    }, 10000)
    const initialLoad = async () => {
      const loaded = await reloadThreads()
      setLoadedThreads([])
      setThreads(unique([...threads, ...loaded], 'id'))
    }
    void initialLoad()
    return () => {
      clearInterval(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (threads && threads.length) {
      setAfter(maxDateValue(threads.map((t) => new Date(t.updated!))))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads])

  const saveThread = async (thread: Pick<Thread, 'message' | 'attachments' | 'roomId'>) => {
    try {
      const newThread = await postThread(thread.roomId, {
        ...thread,
        userId: SessionService.loadSession()!.id!,
        user: SessionService.loadSession()!.username
      })
      setThreads(unique([...threads, ...loadedThreads, newThread], 'id'))
      pushNotification('Message sent', 'success')
    } catch (e) {
      pushNotification('Failed to send message', 'error')
    }
  }

  const deleteThread = async (thread: Thread) => {
    try {
      await putThread(thread.roomId, thread.id!, {
        ...thread,
        deleted: true
      })
      const messages = [...threads, ...loadedThreads].filter((t) => t.id !== thread.id)
      setThreads(unique(messages, 'id'))
      pushNotification('Message deleted', 'success')
    } catch (e) {
      pushNotification('Failed to delete message', 'error')
    }
  }

  const onLoadedThreadsClick = () => {
    setThreads(unique([...threads, ...loadedThreads], 'id'))
    setLoadedThreads([])
  }

  const sortedThreads = threads.sort((a, b) => new Date(b.updated!).getTime() - new Date(a.updated!).getTime())

  const threadActions = (thread: Thread) => {
    return (
      <ChatMessageActionContainer spacing={20}>
        {thread && thread.user === SessionService.loadSession()?.username && (
          <PlainLink
            onClick={() => {
              setDeletableThread(thread)
              setShowDeleteAlert(true)
            }}
          >
            <MdDeleteOutline size={16} />
          </PlainLink>
        )}
      </ChatMessageActionContainer>
    )
  }

  const hasAdminPrivileges = () => {
    const session = SessionService.loadSession()
    if (!session || !roomId) {
      return false
    }
    const room = rooms.find((r) => r.id === roomId)
    return session.id === room?.owner || room?.admins?.includes(session.id!)
  }

  const predefinedRoom = rooms.find((r) => r.id === roomId)

  return (
    <MainContainer>
      {loadedThreads.length > 0 && (
        <LoadedMessagesElement onClick={onLoadedThreadsClick}>
          {loadedThreads.length} new messages loaded. Click to show.
        </LoadedMessagesElement>
      )}
      {sortedThreads.map((t) => {
        const room = rooms.find((c) => c.id === t.roomId)
        return (
          <ChatCard
            room={room!}
            thread={t}
            key={`card_${t.id}`}
            onMessageClick={(thread) => navigate(`/chat/${thread.roomId}/thread/${thread.id}`)}
            actions={threadActions(t)}
          />
        )
      })}
      <AddButton onClick={() => setShowModal(true)} />
      {hasAdminPrivileges() && <EditRoomButton onClick={() => setShowAdminModal(true)} />}
      {hasAdminPrivileges() && predefinedRoom && <RoomAdminModal open={showAdminModal} roomId={predefinedRoom.id!} onClose={() => setShowAdminModal(false)} />}
      <ChatModal
        predefinedRoomId={roomId}
        show={showModal}
        setShow={setShowModal}
        onSubmit={async (message) => {
          await saveThread(message)
          setShowModal(false)
        }}
      />
      <Modal open={!!(showDeleteAlert && deletableThread)} onClose={() => setShowDeleteAlert(false)} role="alertdialog" size="xs">
        <Modal.Body>Are you sure to delete message?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowDeleteAlert(false)} appearance="subtle">
            Cancel
          </Button>
          <Button
            onClick={async () => {
              await deleteThread(deletableThread!)
              setShowDeleteAlert(false)
            }}
            appearance="primary"
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </MainContainer>
  )
}
