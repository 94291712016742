import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'
import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { formatDate } from '../utils/date.utils'
import { PlainLink } from './PlainLink'
import { Room, Thread } from '@petshop/common'
import { UserBadge, ChatRating, ChatBody, ChatTitle, ChatFooter, ChatContent, ChatCardElement, RatingBagde } from './ChatCardElements.tsx'
import { Stack } from 'rsuite'
import { voteThread } from '../api'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { Attachments } from './chat/MessageElements.tsx'

type Props = {
  room: Room
  thread: Thread
  actions?: React.ReactNode
  onMessageClick?: (thread: Thread) => void
}

export const ChatCard: React.FC<Props> = ({ room, thread, actions, onMessageClick }) => {
  const navigate = useNavigate()
  const { pushNotification } = useNotifications()

  const voteUp = async () => {
    try {
      await voteThread(room.id!, thread.id!, 'up')
    } catch (e) {
      pushNotification('Failed to vote message', 'error')
    }
  }

  const voteDown = async () => {
    try {
      await voteThread(room.id!, thread.id!, 'down')
    } catch (e) {
      pushNotification('Failed to vote message', 'error')
    }
  }

  return (
    <ChatCardElement $colors={room.colors}>
      <ChatContent>
        <ChatTitle spacing={8}>
          <RoomBadge onClick={() => navigate(`/chat/${room.id}`)} $colors={room.colors}>
            @{room.name}
          </RoomBadge>
          <UserBadge onClick={() => navigate(`/user/${thread.userId}`)}>{thread.user}</UserBadge>
          <ChatFooter>{formatDate(new Date(thread.updated!))}</ChatFooter>
          {actions}
        </ChatTitle>
        <ChatBody>
          <Stack>{onMessageClick ? <PlainLink onClick={() => onMessageClick(thread)}>{thread.message}</PlainLink> : thread.message}</Stack>
          <Attachments attachments={thread.attachments || []} />
        </ChatBody>
      </ChatContent>
      <ChatRating>
        <PlainLink onClick={() => voteUp()} disabled={thread.voted === 1}>
          <BsCaretUpFill />
        </PlainLink>
        <RatingBagde>{thread.rating}</RatingBagde>
        <PlainLink onClick={() => voteDown()} disabled={thread.voted === -1}>
          <BsCaretDownFill />
        </PlainLink>
      </ChatRating>
    </ChatCardElement>
  )
}

const RoomBadge = styled(PlainLink)<{
  $colors?: { background: string; highlight: string; text: string }
}>`
  border-radius: 4px;
  color: ${(props) => props.$colors?.text || '#E13737'};
  background-color: ${(props) => props.$colors?.background || '#FFEC84'};
`
