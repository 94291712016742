import { MessageContainer, Message } from '../chat/MessageElements.tsx'
import { SessionService } from '../../utils/session.utils.ts'
import { Form, Row, Col, Button, Stack, Input, Grid, Drawer } from 'rsuite'
import { useAtom } from 'jotai/index'
import { privateDiscussionAtom, privateMessagesAtom, privateMessagesAfterAtom } from '../../atoms'
import styled from 'styled-components'
import { useEffect, useState, useRef, FC } from 'react'
import { FooterElement } from '../FooterMenu.tsx'
import { User } from '@petshop/common'
import { postPrivateMessage, getPrivateMessages } from '../../api'
import loadingIcon from '../../images/loading.gif'

type Props = {
  user: User
  open: boolean
  onClose: () => void
}

export const PrivateChat: FC<Props> = ({ user, open, onClose }) => {
  const [privateDiscussion, refreshDiscussion] = useAtom(privateDiscussionAtom)
  const [messages, setPrivateMessages] = useAtom(privateMessagesAtom)
  const [after, setAfter] = useAtom(privateMessagesAfterAtom)
  const [message, setMessage] = useState<string>('')
  const messageRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const timeout = setInterval(async () => {
      if (privateDiscussion) {
        const newMessages = await getPrivateMessages(privateDiscussion.id!, after ? after.getTime() : undefined)
        setPrivateMessages([...new Map([...messages, ...newMessages].map((item) => [item['id'], item])).values()])
        setLoading(false)
        scrollDown()
      }
    }, 2000)
    refreshDiscussion()
    setLoading(true)
    return () => clearInterval(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (messages && messages.length) {
      setAfter(new Date(messages[messages.length - 1].updated!))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  const scrollDown = () => {
    setTimeout(() => {
      containerRef.current?.scrollTo({
        top: containerRef.current?.clientHeight,
        behavior: 'smooth'
      })
    }, 100)
  }

  const sendMessage = async () => {
    try {
      setLoading(true)
      scrollDown()
      const msg = await postPrivateMessage(privateDiscussion!.id!, {
        message,
        discussionId: privateDiscussion!.id!,
        sender: SessionService.loadSession()!.username
      })
      setPrivateMessages([...messages, msg])
      setMessage('')
      messageRef.current?.focus()
      scrollDown()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer size="calc(100% - 120px)" placement="bottom" open={open} onClose={() => onClose()}>
      <Drawer.Header>
        <Drawer.Title>Chat with {user.username}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body ref={containerRef}>
        <Grid fluid style={{ margin: '10px 0' }}>
          {loading && (
            <NoMessageContainer>
              <LoadingIcon src={loadingIcon} alt="" />
            </NoMessageContainer>
          )}
          {!messages.length && !loading && <NoMessageContainer>No discussion history.</NoMessageContainer>}
          {messages.map((message, index) => (
            <MessageContainer
              flow={message.sender === SessionService.loadSession()?.username ? 'right' : 'left'}
              direction="column"
              key={`message_${index}`}
            >
              <Message>{message.message}</Message>
            </MessageContainer>
          ))}
        </Grid>
      </Drawer.Body>
      <ChatFooter>
        <Drawer.Actions>
          <Form onSubmit={() => sendMessage()} disabled={!message}>
            <Row>
              <Col md={16} xs={16} mdOffset={1} xsOffset={1}>
                <Input name="message" onChange={setMessage} disabled={loading} value={message} ref={messageRef} />
              </Col>
              <Col md={4} xs={4} mdPush={2} xsPush={2}>
                <Button appearance="primary" type="submit" disabled={!message}>
                  Send
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer.Actions>
      </ChatFooter>
    </Drawer>
  )
}

const NoMessageContainer = styled(Stack)`
  height: 50px;
  justify-content: center;
  align-items: center;
  color: var(--rs-btn-default-text);
  background-color: var(--rs-btn-default-bg);
  font-weight: bold;
`
const ChatFooter = styled(FooterElement)`
  box-shadow: 0 -5px 5px -5px #999;
  margin-bottom: 4px;
  padding-top: 4px;
`
const LoadingIcon = styled.img`
  height: 24px;
`
