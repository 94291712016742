import { FC, useState } from 'react'
import { Message } from '@petshop/common'
import { PlainLink } from './PlainLink.tsx'
import { FaReply } from 'react-icons/fa'
import { ChatModal } from './ChatModal.tsx'
import { postMessage, putMessage } from '../api'
import { SessionService } from '../utils/session.utils.ts'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { MdDeleteOutline } from 'react-icons/md'
import styled from 'styled-components'
import { Stack, Modal, Button } from 'rsuite'
import { useAtom } from 'jotai'
import { roomIdAtom, threadIdAtom, messagesAtom } from '../atoms'

type Props = {
  messageId?: string
  afterSubmit?: (message: Message) => void
}

export const ChatMessageActions: FC<Props> = ({ messageId, afterSubmit }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false)
  const [roomId] = useAtom(roomIdAtom)
  const [threadId] = useAtom(threadIdAtom)
  const [messages] = useAtom(messagesAtom)
  const { pushNotification } = useNotifications()

  const message = messageId ? messages.find((m) => m.id === messageId) : undefined

  const saveReply = async (newMessage: Pick<Message, 'message' | 'attachments' | 'roomId'>) => {
    try {
      const msg = await postMessage(newMessage.roomId, threadId!, {
        ...newMessage,
        threadId: threadId!,
        parentId: messageId,
        userId: SessionService.loadSession()!.id!,
        user: SessionService.loadSession()!.username
      })
      afterSubmit?.(msg)
    } catch (e) {
      pushNotification('Failed to send message', 'error')
    }
  }

  const deleteMessage = async (message: Message) => {
    try {
      const msg = await putMessage(message.roomId, message.threadId, {
        ...message,
        deleted: true
      })
      afterSubmit?.(msg)
    } catch (e) {
      pushNotification('Failed to delete message', 'error')
    }
  }

  return (
    <ChatMessageActionContainer spacing={20}>
      <PlainLink onClick={() => setShowModal(true)}>
        <FaReply size={16} />
      </PlainLink>
      {message && message.user === SessionService.loadSession()?.username && (
        <PlainLink onClick={() => setShowDeleteAlert(true)}>
          <MdDeleteOutline size={16} />
        </PlainLink>
      )}
      <ChatModal
        predefinedRoomId={roomId}
        show={showModal}
        setShow={setShowModal}
        onSubmit={async (message) => {
          await saveReply(message)
          setShowModal(false)
        }}
      />
      {message && (
        <Modal open={showDeleteAlert} onClose={() => setShowDeleteAlert(false)} role="alertdialog" size="xs">
          <Modal.Body>Are you sure to delete message?</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowDeleteAlert(false)} appearance="subtle">
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await deleteMessage(message)
                setShowDeleteAlert(false)
              }}
              appearance="primary"
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </ChatMessageActionContainer>
  )
}

export const ChatMessageActionContainer = styled(Stack)`
  margin-left: 12px;
`
