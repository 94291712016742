import { z } from 'zod'
import { AttachmentSchema } from './attachment'

export const PrivateMessageSchema = z.object({
  id: z.string().optional(),
  discussionId: z.string(),
  message: z.string(),
  attachments: z.array(AttachmentSchema).optional(),
  sender: z.string(),
  created: z.string().datetime().optional(),
  updated: z.string().datetime().optional(),
  deleted: z.boolean().optional()
})

export type PrivateMessage = z.infer<typeof PrivateMessageSchema>