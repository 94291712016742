import { z } from 'zod'

export const LocationSchema = z.object({
  id: z.string().optional(),
  city: z.string(),
  country: z.string(),
  latitude: z.number(),
  longitude: z.number()
})

export type Location = z.infer<typeof LocationSchema>