import styled from 'styled-components'
import React from 'react'
import { BsGearFill } from 'react-icons/bs'
import { PlainLink } from './PlainLink'

type Props = {
  onClick: () => void
}

export const EditRoomButton: React.FC<Props> = ({ onClick }) => (
  <EditContainer>
    <PlainLink onClick={onClick}>
      <BsGearFill size={70} style={{ fill: '#E13737' }} />
    </PlainLink>
  </EditContainer>
)

const EditContainer = styled.span`
  position: fixed;
  bottom: 60px;
  right: 15px;
  border-radius: 40px;
`
