import styled from 'styled-components'
import { FC, ReactNode } from 'react'
import { PublicUser } from "@petshop/common";
import { Link } from "react-router-dom";

export const UserAvatar: FC<{ user: PublicUser; size?: 'small' | 'large', actions?: ReactNode }> = ({ user, actions, size = 'large'}) => {
  return (
    <FriendInfo $size={size}>
      <Link key={user.id} to={`/user/${user.id}`}>
        <UserImage $avatarUrl={user.avatarUrl} $size={size} />
        <FriendTitle>{user.username}</FriendTitle>
      </Link>
      {actions}
    </FriendInfo>
  )
}

const FriendInfo = styled.div<{ $size: 'small' | 'large' }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: ${({$size}) => $size === 'large' ? '140px;' :  '60px;'}
  width: 100%;
  text-align: center;
`

const FriendTitle = styled.h5``

const UserImage = styled.div<{ $avatarUrl: string | undefined, $size: 'small' | 'large' }>`
  width: 100%;
  height: ${({$size}) => $size === 'large' ? '140px;' :  '60px;'}
  border-radius: 50%;
  object-fit: contain;
  background-color: ${(props) => (props.$avatarUrl ? 'unset' : '#ccc')};
  background-image: ${(props) => (props.$avatarUrl ? `url(${props.$avatarUrl})` : 'none')};
  background-repeat: no-repeat;
  background-size: cover;
`
