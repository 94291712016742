import { FC, useState } from 'react'
import { PublicUser } from '@petshop/common'
import { searchUsers } from '../api'
import { InputPicker } from 'rsuite'

type Props = {
  onChosen: (id: string) => void
}

export const UserAutocomplete: FC<Props> = ({ onChosen }) => {
  const { users, loading, getUsers } = useUsers()
  return (
    <InputPicker
      data={users.map(u => ({ value: u.id, label: u.username }))}
      style={{ width: 224 }}
      onSearch={getUsers}
      onChange={onChosen}
      placeholder={'Search...'}
      renderMenu={menu => {
        if (loading) {
          return (
            <p style={{ padding: 10, color: '#999', textAlign: 'center' }}>
              Loading...
            </p>
          )
        }
        return menu
      }}
    />
  )
}

const useUsers = () => {
  const [users, setUsers] = useState<PublicUser[]>([])
  const [loading, setLoading] = useState(false)
  const getUsers = async (searchWord: string) => {
    setLoading(true)
    try {
      const data = await searchUsers(searchWord)
      setUsers(data)
    } finally {
      setLoading(false)
    }
  }
  return { users, loading, getUsers }
}