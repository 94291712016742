import React from 'react'
import { formatDate } from '../utils/date.utils'
import { Message, Thread } from '@petshop/common'
import {
  ChatCardElement,
  ChatContent,
  ChatTitle,
  UserBadge,
  ChatBody,
  ChatRating,
  RatingBagde,
  ChatFooter,
  DeletedMessage
} from './ChatCardElements.tsx'
import { PlainLink } from './PlainLink.tsx'
import { BsCaretUpFill, BsCaretDownFill } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { Stack } from 'rsuite'
import { ChatMessageActions } from './ChatMessageActions.tsx'
import { voteMessage } from '../api'
import { useNotifications } from '../hooks/useNotifications.tsx'
import { Attachments } from './chat/MessageElements.tsx'

type Props = {
  thread: Thread
  message: Message
  remainingMessages: Message[]
  level: number
  cardStyle?: React.CSSProperties
  actions?: React.ReactNode
  reloadMessages: () => Promise<void>
}

export const ThreadMessageCard: React.FC<Props> = ({ thread, message, remainingMessages, level, cardStyle, actions, reloadMessages }) => {
  const { pushNotification } = useNotifications()

  const filteredMessages = remainingMessages.filter((rm) => rm.parentId === message.id)
  const navigate = useNavigate()

  const messageActions = (parentMessageId: string) => {
    return <ChatMessageActions messageId={parentMessageId} afterSubmit={async () => {
      await reloadMessages()
    }} />
  }

  if (message.deleted) {
    return <DeletedMessage style={{ marginLeft: `${level * 16}px` }}>This message has been deleted</DeletedMessage>
  }

  const voteUp = async () => {
    try {
      await voteMessage(thread.roomId, message.threadId, message.id!, 'up')
    } catch (e) {
      pushNotification('Failed to vote message', 'error')
    }
  }

  const voteDown = async () => {
    try {
      await voteMessage(thread.roomId, message.threadId, message.id!, 'down')
    } catch (e) {
      pushNotification('Failed to vote message', 'error')
    }
  }

  return (
    <>
      <ChatCardElement style={{ marginLeft: `${level * 16}px`, ...cardStyle }}>
        <ChatContent>
          <ChatTitle spacing={8}>
            <UserBadge onClick={() => navigate(`/user/${message.userId}`)}>{message.user}</UserBadge>
            {actions}
          </ChatTitle>
          <ChatBody>
            <Stack>{message.message}</Stack>
            <Attachments attachments={message.attachments || []} />
            <ChatFooter>{formatDate(new Date(message.updated!))}</ChatFooter>
          </ChatBody>
        </ChatContent>
        <ChatRating>
          <PlainLink onClick={() => voteUp()} disabled={message.voted === 1}>
            <BsCaretUpFill />
          </PlainLink>
          <RatingBagde>{message.rating}</RatingBagde>
          <PlainLink onClick={() => voteDown()} disabled={message.voted === -1}>
            <BsCaretDownFill />
          </PlainLink>
        </ChatRating>
      </ChatCardElement>
      {filteredMessages.map((rm) => (
        <ThreadMessageCard
          key={`threadmessage_${rm.id}`}
          thread={thread}
          message={rm}
          remainingMessages={remainingMessages}
          level={level < 4 ? level + 1 : level}
          cardStyle={cardStyle}
          actions={messageActions(rm.id!)}
          reloadMessages={reloadMessages}
        />
      ))}
    </>
  )
}
